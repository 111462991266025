import '../style/HomePage.css';
import 'alertifyjs/build/css/alertify.css';
import Card from '../componentes/Card';
import Destacado from '../componentes/Destacado';
import tapasAventurasDe from '../imagenes/tapasAventurasDe.jpg';
import cardBuenSaber from '../imagenes/cardBuenSaber.jpg';
import infantilesCard from '../imagenes/infantilesCard.jpg';
import adolescentesCard from '../imagenes/adolescentesCard.jpg';
import adultosCard from '../imagenes/adultosCard.jpg';
import tecnologiaCard from '../imagenes/tecnologiaCard.jpg';

import React, { useEffect } from 'react';

import alertify from 'alertifyjs';


function HomePage() {
  
  // Función para mostrar la alerta
  useEffect(() => {
    alertify.alert('Aviso de Cookies', 'En este sitio web se utilizan cookies para analizar el tráfico y medir los anuncios. <a href="https://www.elbuensaber.com/infocookies">Más información sobre cómo usamos las cookies</a>');
  }, []);
  

  return (
    <main className='main-home-page'>
      
      <Destacado
        imagen={'https://m.media-amazon.com/images/I/71o3yNQnuZL._SY522_.jpg'}
        altImagen={'Imágen libro Cumbres Borrascosas II, Amores del más allá'}
        tituloGeneral={'NOVEDAD! Cumbres Borrascosas II'}
        tituloLibro={'Cumbres Borrascosas II, Amores del más allá'}
        texto1={'Sobre Cumbres Borrascosas II:'}
        texto2={'Esta es la continuación de la obra maestra de Emily Brontë, Cumbres Borrascosas, escrita por Marcela Jerochim, docente de nivel medio y superior. En las desoladas tierras de Cumbres Borrascosas, donde el viento susurra secretos de amores perdidos y rencores eternos, las almas de Catherine y Heathcliff vagan sin descanso. Renacen en los vibrantes años 50 del siglo XX, en la enigmática ciudad de Bruselas, sin memoria de su tormentoso pasado. Sus corazones laten al ritmo de antiguas pasiones y errores olvidados. Louis y Jeanne, las nuevas encarnaciones de Heathcliff y Catherine, se encuentran atrapados en un destino tejido con hilos de redención y perdón. Mientras los villanos del pasado resurgen con sed de venganza, el amor y el odio se entrelazan en una danza eterna, trascendiendo el tiempo y el espacio. Misteriosas fuerzas guían sus pasos, revelando lentamente los secretos que sus almas han guardado durante mucho tiempo. Marcela Jerochim, profesora de Ciencias Económicas, nos ofrece una narrativa cautivadora que explora las profundidades del alma humana y la posibilidad de redención más allá de la muerte. Esta novela es un canto a las segundas oportunidades, donde el amor y la redención se convierten en la única esperanza para las almas perdidas. Déjate llevar por esta emocionante continuación que promete envolverte en un torbellino de emociones y misterio. Descubre cómo el amor puede sanar las heridas más profundas y cómo la redención puede cambiar el destino de las almas atormentadas.  '}
      />
      <div className='contenedor-cards'> 
        <Card 
          url={'/personalizados'}
          imagen={tapasAventurasDe}
          titulo={'Libros Personalizados'}
          descripcion={'Personaliza tu libro'}
        />
        <Card 
          url={'/librosbs'}
          imagen={cardBuenSaber}
          titulo={'Libros El Buen Saber'}
          descripcion={'Nuestros Títulos Originales!!!'}
        />
        <Card 
          url={'/infantiles'}
          imagen={infantilesCard}
          titulo={'Libros y Cuentos Infantiles'}
          descripcion={'Para los más pequeños!!!'}
        />
        <Card 
          url={'/adolescentes'}
          imagen={adolescentesCard}
          titulo={'Libro para Jóvenes'}
          descripcion={'Con toda la emoción y aventura!!!'}
        />
        <Card 
          url={'/adultos'}
          imagen={adultosCard}
          titulo={'Libros para Adultos'}
          descripcion={'Historia, ficción, novelas, autoayuda'}
        />
        <Card 
          url={'/tecnologia'}
          imagen={tecnologiaCard}
          titulo={'Tecnología'}
          descripcion={'e-readers, tablets, portátiles y mas...'}
        />
        
      </div>
    </main>
  );
};

export default HomePage;




